// Imports
// ------
import styled, { css } from 'styled-components';
import { Div } from '@tackl';
import {
	h1Styles,
	h2Styles,
	h3Styles,
	h4Styles,
	h5Styles,
	emStyles,
	pStyles,
} from '@type';

// Exports
// ------
export const Jacket = styled(Div)(
	(props) => css`
		em {
			${emStyles}
		}

		h1 {
			${h1Styles}
		}

		h2 {
			${h2Styles}
		}

		h3 {
			${h3Styles}
		}

		h4 {
			${h4Styles}
		}

		h5 {
			${h5Styles}
		}

		p {
			display: block;
			margin-bottom: 2.4rem;

			${pStyles}

			strong {
				font-weight: 700;
			}
		}

		ul {
			margin: 2.4rem 0;
			margin-left: 2.4rem;
			list-style: disc outside;

			li {
				${pStyles}
			}
		}
	`
);
