// Imports
// ------
import React from 'react';
import parse from 'html-react-parser';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const HTMLParser = ({ children, toParse }) => (
	<Jacket>
		{children && children}
		{toParse && parse(toParse)}
	</Jacket>
);

export default HTMLParser;
